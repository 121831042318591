.LeftToRight{
  position: fixed;
  top: 0;
  height: 100%;
  left: -100%;
  // overflow: auto;
  z-index: 11;
}

.LeftToRight-enter {
  left: -100%;
}
.LeftToRight-enter-active {
  left: 0;
  transition: left 200ms;
}
.LeftToRight-exit {
  left: 0;
}
.LeftToRight-exit-active {
  left: -100%;
  transition: left 200ms;
}

.LeftToRight-exit-done{
  left: -100%;
}

.LeftToRight-enter-done{
  left: 0;
}


.RightToLeft-enter {
  right: -100%;
}
.RightToLeft-enter-active {
  right: 0;
  transition: right 200ms;
}
.RightToLeft-exit {
  right: 0;
}
.RightToLeft-exit-active {
  right: -100%;
  transition: right 200ms;
}

.RightToLeft-exit-done{
  right: -100%;
}

.RightToLeft-enter-done{
  right: 0;
}




.BottomToTop-enter {
  bottom: -1000px;
}
.BottomToTop-enter-active {
  bottom: 0;
  transition: bottom 200ms;
}
.BottomToTop-exit {
  bottom: 0;
}
.BottomToTop-exit-active {
  bottom: -1000px;
  transition: bottom 200ms;
}

.BottomToTop-exit-done{
  bottom: -1000px;
}

.BottomToTop-enter-done{
  bottom: 0;
}